import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import ImageEditor from './imageEditor'
import GenerateOptions from './generateOptions'
import Imagelist from './imageList'

export default function Body() {

    useEffect(() => {

    }, [])
    return (
        <Container maxWidth="lg">
            <Box sx={{ flexGrow: 1, mt: 4 }} >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <ImageEditor></ImageEditor>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {/* <HistoryDrawer></HistoryDrawer> */}
                        <GenerateOptions></GenerateOptions>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Imagelist></Imagelist>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}