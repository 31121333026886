
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import React, { useState, useEffect, createContext } from 'react';


import TopBar from './components/topbar'
import Body from './components/body'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import SignIn from './pages/signin';
import SignUp from './pages/signup'
import HomePage from './pages/home'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
   }
});
export const StateContext = createContext()
let BASE_URL = ''
if (window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1')) {
  BASE_URL = 'http://127.0.0.1:8000';
}
else {
  BASE_URL = 'https://interiorai.uc.r.appspot.com'
}
export const Apis = {
  "user_gen": BASE_URL + "/stability/gen_images/"
  , "moodboard": BASE_URL + "/stability/moodboard_v2"
  , "get_prompt_data": BASE_URL + "/stability/get_prompt_data_v2"
  , "generate_variations": BASE_URL + "/stability/generate_variation_v2"
  , "get_mask_images": BASE_URL + "/stability/mask2former"
  , "make_mask_from_images": BASE_URL + "/stability/make_mask_from_images"
  , "login": BASE_URL + "/apis/app_user/login/"
  , "logout": BASE_URL + "/apis/app_user/logout/"
  , "validate_auth": BASE_URL + '/apis/app_user/validate_auth/'
  , "signup": BASE_URL + '/apis/app_user/signup/'
}

const pages = ['home', 'signin', 'signup', 'generate']
export function App() {
  const [state, setState] = useState({

    image: null
    , imageList: []
    , totalPages: 1
    , currentPage: 1
    , imageLoaderOn: true
    , isGenerating: false
    , isFSLoading: false
    , isMask: false
    , segmentMask: null
    , bwMask: null
    , maskOptions: [] // Original mask objects lable
    , selectedMaskOptions: [] // Original selected mask objects labels
    , displayMaskOptions: [{ 'label': 'Furnishing' }, { 'label': 'Architectural' }] // Mask objects to display
    , displaySelectedMaskOptions: [] //selected mask objects to display
    , canvasImage: null
    , pageName: 'home'
    , authenticationToken: null
  })
  useEffect(() => {
    const authenticationToken = localStorage.getItem('authenticationToken');
    if (authenticationToken !== null) {
      fetch(Apis.validate_auth, {
        method: 'GET',
        headers: { Authorization: 'Token ' + authenticationToken }
      }).then((response) => {
        if (response.status === 200) {
          setState({...state, authenticationToken: authenticationToken})
        }
        else{
          localStorage.removeItem('authenticationToken');
        }
      })
    }
  }, [])
  return (
    <StateContext.Provider value={{ state: state, setState: setState }}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <TopBar></TopBar>
        {(state.pageName === 'home') && <HomePage></HomePage>}
        {(state.pageName === 'signin') && <SignIn></SignIn>}
        {(state.pageName === 'signup') && <SignUp></SignUp>}
        {
          (state.pageName === 'generate') && (
            state.authenticationToken === null ?
              <SignIn></SignIn>
              :
              <Body></Body>
          )
        }
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={state.isFSLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </ThemeProvider>
    </StateContext.Provider>

  );
}

// export default App;
