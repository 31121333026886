import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { StateContext, Apis } from '../App'
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import Skeleton from '@mui/material/Skeleton';
import DownloadIcon from '@mui/icons-material/Download';


export default function Moodboard() {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { state, setState } = useContext(StateContext);
    // const [selected, setSelected] = useState([]);
    const [moodboardState, setMoodboardState] = useState({
        selected: []
        , isLoading: false
        , mbImage: ''
    })
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const cardClick = (id) => {

        const item = moodboardState.selected.filter((item_id) => item_id === id)
        if (item.length === 0) {
            setMoodboardState((moodboardState) => ({ ...moodboardState, selected: moodboardState.selected.concat(id) }))
        }
        else {
            setMoodboardState((moodboardState) => ({ ...moodboardState, selected: moodboardState.selected.filter(item => item !== id) }))
        }
    }
    const generateMoodboard = () => {
        let formData = new FormData();
        formData.append("ids", JSON.stringify(moodboardState.selected));
        setMoodboardState(() => ({ ...moodboardState, isLoading: true }))
        fetch(Apis.moodboard, {
            method: "POST"
            , headers: { Authorization: 'Token ' + state.authenticationToken }
            , body: formData
        }).then((response) => {
        if (!response.ok) {
            throw new Error('Server Error');
        }
        else {
            response.json().then((data) => {
                const moodboardImage = 'data:image/png;base64,' + JSON.parse(data.moodboard)
                setMoodboardState(() => ({ ...moodboardState, isLoading: false, mbImage: moodboardImage }))
            })
        }

    }).catch((response) => {
        console.log(response)
        setMoodboardState(() => ({ ...moodboardState, isLoading: false }))
    })
}
function download() {
    // Create a new anchor element
    var a = document.createElement("a"); //Create <a>
    a.href = moodboardState.mbImage; //Image Base64 Goes here
    a.download = "Image.png"; //File name Here
    a.click();
}
useEffect(() => {

})
return (
    <div style={{ display: 'inline-block' }}>
        <Button
            variant="outlined"
            size="small"
            sx={{ ml: '10px' }}
            color="success"
            onClick={handleClickOpen}
            disabled={state.imageLoaderOn}>
            Moodboard
        </Button>
        <Dialog
            fullScreen={fullScreen}
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                Generate Boodboard
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContentText>
                    {moodboardState.selected.length} Items Selected
                </DialogContentText>
            </DialogTitle>
            <DialogContent>

                <Grid container sx={{ mt: 0 }} spacing={1}>

                    <Grid item xs={12} md={5} sx={{ mt: 1 }} >
                        {
                            (moodboardState.mbImage !== "") ?
                                <Card>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="auto"
                                            image={moodboardState.mbImage}
                                            alt="Moodboard"
                                            width="100%"
                                        />
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size="small" endIcon={<DownloadIcon />} onClick={download}>
                                            Download
                                        </Button>
                                    </CardActions>
                                </Card>
                                :
                                <Stack spacing={1}>
                                    <Skeleton variant="rectangular" width="100%" height="192px" />
                                    <Skeleton variant="rectangular" width="100%" height={40} />
                                </Stack>
                        }




                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Grid container spacing={1} sx={{ mt: 0 }}>
                            {state.imageList.map((gen) => (
                                <Grid key={gen.id} item xs={6} md={3}>
                                    <Card style={{ border: moodboardState.selected.includes(gen.id) ? "solid 3px lightblue" : "none" }} onClick={() => cardClick(gen.id)}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="auto"
                                                image={gen.gen_image_small}
                                                alt="green iguana"
                                                style={{ aspectRatio: "1024/768" }}
                                            />
                                            {/* <CardContent sx={{ mt: '-64px', backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'absolute', width: '100%', p: '10px', border: '' }} >
                                                    <Typography variant="body1" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                        {gen.gen_data.Space}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                        {gen.gen_data.DesignTheme}
                                                    </Typography>
                                                </CardContent> */}
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="small"
                    onClick={generateMoodboard}
                    loading={moodboardState.isLoading}
                    // variant="outlined"
                    disabled={moodboardState.selected.length < 2}
                    autoFocus
                >
                    <span>Generate</span>
                </LoadingButton>
                {/* <Button >
                        Generate
                    </Button> */}
            </DialogActions>
        </Dialog>
    </div>
);
}
