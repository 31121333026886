import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { StateContext, Apis } from '../App'
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import ListItemText from '@mui/material/ListItemText';
// import CircularProgress from '@mui/material/CircularProgress';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Buffer } from 'buffer';
import LoadingButton from '@mui/lab/LoadingButton';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import ProgressBar from './progress';


function GenerateOption({ data, name, label, selected, setSelected }) {

    const handleChange = (event) => {
        setSelected(event.target.value)
    };
    useEffect(() => {

    }, [data, selected])
    return (
        <FormControl variant="outlined" sx={{ mb: 1, mt: 1 }} fullWidth={true} size="small">

            <InputLabel id={name + '-label'}>{label}</InputLabel>
            <Select
                labelId={name + '-label'}
                name={name}
                value={selected}
                onChange={handleChange}
                label={name}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {
                    data ?
                        Object.keys(data).map((key) => (
                            <MenuItem key={key} value={key}>{key}</MenuItem>
                        ))
                        :
                        <span></span>
                }
            </Select>
        </FormControl>
    )
}


export default function GenerateOptions() {
    const { state, setState } = useContext(StateContext);
    const [promptData, setPromptData] = useState({})
    const [selectedSpace, setSelectedSpace] = useState("")
    const [selectedDesign, setSelectedDesign] = useState("")
    const [selectedLight, setSelectedLight] = useState("")
    const [selectedTexture, setSelectedTexture] = useState("")
    const [selectedPattern, setSelectedPattern] = useState("")
    const [selectedColorPalette, setSelectedColorPalette] = useState("")
    const [selectedColor, setSelectedColor] = useState("")
    // const [maskImages, setMaskImages] = useState([]);
    // const [isLoadingMask, setIsLoadingMask] = useState(false)


    async function getImageBase64(url) {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            return await convertBlobToBase64(blob);
        } catch (error) {
            console.error('Error:', error);
            return null;
        }
    }

    function convertBlobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }
    const getImageFromUrl = async (url) => {
        // Return a promise that fetch Base64image from url
        const base64image = await getImageBase64(url)
        return base64image

    }
    const getMaskImage = async () => {
        const buffer = Buffer.from(state.image.split(',')[1], 'base64');
        const image = new Blob([buffer], { type: 'image/jpeg' });
        let formData = new FormData();
        formData.append("image", image);

        setState({ ...state, isFSLoading: true })
        fetch(Apis.get_mask_images, {
            method: "POST"
            , headers: { Authorization: 'Token ' + state.authenticationToken }
            , body: formData
            , processData: false
            , contentType: false
        }).then((response) => {
            if (!response.ok) {
                setState({
                    ...state
                    , isFSLoading: false
                })
                throw new Error(response);
            }
            else {
                response.json().then((data) => {
                    console.log(data)
                    getImageFromUrl(data.response?.segment).then((base64image) => {
                        console.log(data.response?.objects)
                        setState({
                            ...state
                            , isMask: true
                            , segmentMask: base64image
                            , maskOptions: data.response?.objects
                            , isFSLoading: false
                        })
                    })

                })
            }

        }).catch((response) => {
            console.log("error: ", response)
            setState({
                ...state
                , isFSLoading: false
            })
        })
    }

    const setPalette = (val) => {
        setSelectedColorPalette(val)
        setSelectedColor("")
    }
    const isMaskChange = (event) => {
        const isChecked = event.target.checked
        setState({ ...state, isMask: isChecked })
        if (isChecked === true) {
            if (state.segmentMask === null) {
                getMaskImage()
            }
            else {
                setState({ ...state, isMask: true })
            }
        }
        if (isChecked === false) {
            setState({
                ...state
                , isMask: false
                , bwMask: null
                , selectedMaskOptions: []
                , canvasImage: state.image
            })
        }
    }

    const setMask = async (displayMaskObjs) => {

        let maskObjs = []
        displayMaskObjs.forEach((maskObj) => {
            state.maskOptions.forEach((maskOption) => {
                if (['wall', 'floor', 'windowpane', 'window', 'ceiling', 'rug', 'carpet', 'carpeting', 'fence', 'fencing', 'field', 'column', 'pillar', 'stairs', 'step', 'door'].includes(maskOption.label)) {
                    if (maskObj.label === 'Architectural') {
                        maskObjs.push(maskOption)
                    }
                }
                else {
                    if (maskObj.label === 'Furnishing') {
                        maskObjs.push(maskOption)
                    }
                }

            })

        })
        console.log(maskObjs)

        if (maskObjs.length === 0) {
            setState((state) => ({
                ...state,
                bwMask: null,
                canvasImage: state.image,
                selectedMaskOptions: [],
                displaySelectedMaskOptions: []
            }));
            return;
        }

        const segmentMask = new Image();
        segmentMask.src = state.segmentMask;

        const image = new Image();
        image.src = state.image;

        const loadImage = (image) => {
            return new Promise((resolve) => {
                image.onload = () => resolve();
            });
        };

        const canvasToDataURL = (canvas) => {
            return new Promise((resolve) => {
                canvas.toBlob((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.readAsDataURL(blob);
                });
            });
        };

        await Promise.all([loadImage(segmentMask), loadImage(image)]);

        const canvas = document.createElement("canvas");
        canvas.width = segmentMask.width;
        canvas.height = segmentMask.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(segmentMask, 0, 0);

        const imageData = ctx.getImageData(0, 0, segmentMask.width, segmentMask.height);
        const data = imageData.data;

        for (let i = 0; i < data.length; i += 4) {
            const r = data[i];
            const g = data[i + 1];
            const b = data[i + 2];

            const match = maskObjs.some((colorObj) => {
                const color = colorObj.color;
                return r === color[0] && g === color[1] && b === color[2];
            });

            data[i] = data[i + 1] = data[i + 2] = match ? 255 : 0;
            data[i + 3] = match ? 200 : 0;
        }

        ctx.putImageData(imageData, 0, 0);

        const maskImageDataURL = await canvasToDataURL(canvas);

        const imageCanvas = document.createElement("canvas");
        imageCanvas.width = image.width;
        imageCanvas.height = image.height;
        const ctxImageCanvas = imageCanvas.getContext("2d");
        ctxImageCanvas.drawImage(image, 0, 0);

        const maskImage = new Image();
        maskImage.src = maskImageDataURL;

        await loadImage(maskImage);

        ctxImageCanvas.drawImage(maskImage, 0, 0);

        const canvasImage = await canvasToDataURL(imageCanvas);

        setState((state) => ({
            ...state,
            bwMask: maskImageDataURL,
            canvasImage: canvasImage,
            selectedMaskOptions: maskObjs,
            displaySelectedMaskOptions: displayMaskObjs
        }));
    };


    useEffect(() => {
        if (Object.keys(promptData).length === 0) {
            fetch(Apis.get_prompt_data, {
                method: "GET"
                , headers: { Authorization: 'Token ' + state.authenticationToken }
            }).then((response) => {
                if (!response.ok) {
                    throw new Error('Server Error');
                }
                else {
                    response.json().then((data) => {
                        const palette = {}
                        Object.keys(data['ColorPalette']).forEach((key) => {
                            palette[key] = key
                        })
                        data["Palette"] = palette
                        setPromptData(data)
                    })
                }

            }).catch((response) => {
                console.log(response)
            })
        }
    }, [promptData])

    function generate() {
        const imageBuffer = Buffer.from(state.image.split(',')[1], 'base64');
        const image = new Blob([imageBuffer], { type: 'image/jpeg' });

        var mask = null
        if (state.bwMask) {
            const maskBuffer = Buffer.from(state.bwMask.split(',')[1], 'base64');
            mask = new Blob([maskBuffer], { type: 'image/png' });
        }

        const prompt_data = {
            Space: selectedSpace
            , DesignTheme: selectedDesign
            , Lights: selectedLight
            , Textures: selectedTexture
            , Patterns: selectedPattern
            , ColorPalette: selectedColorPalette
            , Color: selectedColor
        }
        let formData = new FormData();
        formData.append("image", image);
        formData.append("mask", mask)
        formData.append("prompt_data", JSON.stringify(prompt_data))
        formData.append("is_mask", state.isMask)
        formData.append("mask_objects", JSON.stringify(state.displaySelectedMaskOptions))
        // formData.append("structure_variation", $('[name=structureOption]').val())

        // setState({...state, imageLoaderOn:true})

        setState({ ...state, isGenerating: true })
        // setTimeout(() => {
        //     setState({...state, isGenerating:false, imageLoaderOn:true})
        // }, 1000);

        fetch(Apis.generate_variations, {
            method: "POST"
            , headers: { Authorization: 'Token ' + state.authenticationToken }
            , body: formData
            , processData: false
            , contentType: false
        }).then((response) => {
            if (!response.ok) {
                throw new Error('Server Error');
            }
            else {
                response.json().then((data) => {
                    setState({ ...state, imageLoaderOn: true })
                })
            }

        }).catch((response) => {
            console.log("error: ", response)
            setState({ ...state, isGenerating: false })
        })
    }
    return (

        <Box>
            <Typography variant='h5'>Design Options</Typography>
            {
                (Object.keys(promptData).length === 0) ?
                    <Stack spacing={1} sx={{ mt: 1 }}>
                        <Skeleton variant="rounded" animation="wave" width={"auto"} height={50} />
                        <Skeleton variant="rounded" animation="wave" width={"auto"} height={50} />
                        <Skeleton variant="rounded" animation="wave" width={"auto"} height={50} />
                    </Stack>
                    :
                    <Box>
                        <GenerateOption
                            data={promptData['Space']}
                            name="Space"
                            label={"Space*"}
                            selected={selectedSpace}
                            setSelected={setSelectedSpace}
                        ></GenerateOption>
                        <GenerateOption
                            data={promptData['DesignTheme']}
                            name="DesignTheme"
                            label="Design Theme*"
                            selected={selectedDesign}
                            setSelected={setSelectedDesign}
                        ></GenerateOption>

                        <Accordion sx={{ mt: 1, mb: 1 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Other Options </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <GenerateOption
                                    data={promptData['Palette']}
                                    name="ColorPalette"
                                    label="Color Palette"
                                    selected={selectedColorPalette}
                                    setSelected={setPalette}
                                ></GenerateOption>
                                {
                                    (selectedColorPalette !== "") ?
                                        <GenerateOption
                                            data={promptData['ColorPalette'][selectedColorPalette]}
                                            name="Color"
                                            label="Color"
                                            selected={selectedColor}
                                            setSelected={setSelectedColor}
                                        ></GenerateOption>
                                        :
                                        <span></span>
                                }
                                <GenerateOption
                                    data={promptData['Lights']}
                                    name="Lights"
                                    label="Lights"
                                    selected={selectedLight}
                                    setSelected={setSelectedLight}
                                ></GenerateOption>
                                <GenerateOption
                                    data={promptData['Textures']}
                                    name="Textures"
                                    label="Textures"
                                    selected={selectedTexture}
                                    setSelected={setSelectedTexture}
                                ></GenerateOption>
                                <GenerateOption
                                    data={promptData['Patterns']}
                                    name="Patterns"
                                    label="Patterns"
                                    selected={selectedPattern}
                                    setSelected={setSelectedPattern}
                                ></GenerateOption>

                            </AccordionDetails>
                        </Accordion>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <FormControlLabel
                                    sx={{ mb: 1, mt: 1 }}
                                    onChange={isMaskChange}
                                    control={<Switch checked={state.isMask} />}
                                    label="Mask"
                                    disabled={(state.image === null) ? true : false}
                                />
                            </Grid>
                            <Grid item xs>
                                <FormControl variant="outlined" sx={{ mb: 1, mt: 1 }} fullWidth size="small">
                                    <Autocomplete
                                        multiple
                                        hidden={((state.maskOptions.length === 0) | (!state.isMask)) ? true : false}
                                        limitTags={1}
                                        id="checkboxes-tags-demo"
                                        options={state.displayMaskOptions}
                                        disableCloseOnSelect
                                        value={state.displaySelectedMaskOptions}
                                        getOptionLabel={(option) => option.label}
                                        size="small"
                                        onChange={(event, newValue) => setMask(newValue)}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    // icon={icon}
                                                    // checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.label}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select Element" ></TextField>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Box sx={{ mt: 1 }}>
                            {/* <Button fullWidth={true} variant="outlined" onClick={generate} disabled={((selectedSpace === "") | (selectedDesign === "") | (state.image === null) ? true : false)}>
                                Generate
                            </Button> */}
                            <LoadingButton
                                // size="small"
                                fullWidth={true}
                                onClick={generate}
                                endIcon={<ColorLensIcon />}
                                // loading={((state.isGenerating === true) | (state.imageLoaderOn === true))===1}
                                loading={state.isGenerating}
                                disabled={((selectedSpace === "") | (selectedDesign === "") | (state.image === null) ? true : false)}
                                loadingPosition="end"
                                variant="outlined"
                            >
                                <span>Generate New Design</span>
                            </LoadingButton>
                            {
                                state.isGenerating && <ProgressBar seconds={30}></ProgressBar>
                            }

                        </Box>
                    </Box>
            }
        </Box >
    )
}
