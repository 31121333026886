import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { StateContext, Apis } from '../App'
import DeleteIcon from '@mui/icons-material/Delete';
// import LoadingButton from '@mui/lab/LoadingButton';
import Pagination from '@mui/material/Pagination';
// import CircularProgress from '@mui/material/CircularProgress';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { ReactCompareSlider, ReactCompareSliderImage, ReactCompareSliderHandle } from 'react-compare-slider';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import DownloadIcon from '@mui/icons-material/Download';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import Masonry from '@mui/lab/Masonry';


import CardActions from '@mui/material/CardMedia';
import Moodboard from './moodboard'

function GenViewer({ modalState, setModalState }) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setModalState({ clickedItem: {}, modalOpen: false });
    };
    function download() {
        // Create a new anchor element
        const link = document.createElement('a');
        link.href = modalState.clickedItem.gen_image;
        link.setAttribute('download', 'image.jpg');
        link.setAttribute('target', '_blank');

        // Append the anchor element to the body
        document.body.appendChild(link);

        // Trigger a click event on the anchor element
        link.click();

        // Remove the anchor element from the body
        document.body.removeChild(link);
    }
    useEffect(() => {

    }, [modalState])
    return (
        <div>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open responsive dialog
            </Button> */}
            <Dialog
                maxWidth="md"
                fullScreen={fullScreen}
                open={modalState.modalOpen}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Generated Image"}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ mt: 0 }}>
                        <Grid item xs={12} md={8}>
                            <Card style={{ marginBottom: '15px', marginTop: '10px' }}>
                                {/* <CardActionArea> */}
                                <ReactCompareSlider
                                    handle={<ReactCompareSliderHandle buttonStyle={{ WebkitBackdropFilter: 'none', backdropFilter: 'none', border: 0, boxShadow: 'none' }} linesStyle={{ opacity: 0 }} />}
                                    itemOne={<ReactCompareSliderImage src={modalState.clickedItem.init_image} srcSet={modalState.clickedItem.init_image} alt="Image one" />}
                                    itemTwo={<ReactCompareSliderImage src={modalState.clickedItem.gen_image} srcSet={modalState.clickedItem.gen_image} alt="Image two" />}
                                    position={50}
                                />
                                {/* </CardActionArea> */}
                                <CardActions sx={{ p: 1 }}>
                                    <Button size="small" endIcon={<DownloadIcon />} onClick={download}>
                                        Download
                                    </Button>
                                </CardActions>
                            </Card>

                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography variant='h6'>
                                Generation Properties
                            </Typography>

                            <TableContainer>
                                <Table aria-label="simple table" size="small">
                                    {/* <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Col1 </TableCell>
                                            <TableCell align="left">Col2</TableCell>
                                        </TableRow>
                                    </TableHead> */}
                                    <TableBody>
                                        {
                                            Object.keys(modalState.clickedItem).length > 0 ?

                                                Object.keys(modalState.clickedItem.gen_data).map((key, i) => (
                                                    (modalState.clickedItem.gen_data[key] !== "") ?
                                                        <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell component="th" scope="row">
                                                                {key}
                                                            </TableCell>
                                                            <TableCell align="left">{modalState.clickedItem.gen_data[key]}</TableCell>
                                                        </TableRow>
                                                        :
                                                        <TableRow key={i}></TableRow>
                                                ))

                                                :
                                                <TableRow></TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </DialogContent>

                {/* <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Disagree
                    </Button>
                    <Button onClick={handleClose} autoFocus>
                        Agree
                    </Button>
                </DialogActions> */}
            </Dialog>
        </div>
    );
}

export default function Imagelist() {
    const { state, setState } = useContext(StateContext);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const [modalState, setModalState] = useState(
        {
            clickedItem: {}
            , modalOpen: false
        }
    )

    const clickCard = (id) => {
        if (state.imageList.find((val) => val.id === id) !== undefined) {
            setModalState({ clickedItem: state.imageList.find((val) => val.id === id), modalOpen: true });
        }
    };

    const deleteGen = (id) => {
        fetch(Apis.user_gen + id, {
            method: "Delete"
            , headers: { Authorization: 'Token ' + state.authenticationToken }
        }).then((response) => {
            if (response.status === 204) {
                loadImageList(state.currentPage)
                // const newList = state.imageList.filter((item) => item.id !== id)
                // setState(state => ({ ...state, imageList: newList }))
            }
        })
    }
    const loadImageList = (page) => {
        fetch(Apis.user_gen + '?page=' + page, {
            method: "GET"
            , headers: { Authorization: 'Token ' + state.authenticationToken }
        }).then((response) => {
            if (!response.ok) {
                throw new Error('Server Error');
            }
            else {
                response.json().then((resp) => {
                    const data = resp.results
                    const remaining = data.filter((item) => item.is_done === false)

                    if (remaining.length === 0) {
                        // If no item remaining, then stop fetching
                        setState(state => ({
                            ...state,
                            imageList: data
                            , imageLoaderOn: false
                            , isGenerating: false
                            , totalPages: resp.total_pages
                        }))
                    }
                    else {
                        setTimeout(() => {
                            setState(state => ({ ...state, imageList: data }))
                        }, 5000)

                    }
                })
            }

        }).catch((response) => {
            console.log("error: ", response)
            setState(state => ({ ...state, imageLoaderOn: false, isGenerating: false }))
        })
    }


    const changePage = (event, value) => {
        if (value <= state.totalPages) {
            loadImageList(value)
            setState({ ...state, currentPage: value })
        }
    }
    useEffect(() => {
        // const interval = setInterval(() => {
        if (state.imageLoaderOn === true) {
            loadImageList(1)
        }
        // }, 5000)

        // return () => clearInterval(interval);
    }, [state.imageLoaderOn])



    return (
        <React.Fragment>
            <div>
                <Typography variant='h5' style={{ display: 'inline-block' }}>Your Designs</Typography>
                <Moodboard ></Moodboard>
            </div>
            <Box sx={{ mt: isDesktop ? 2 : 1 }}>
                <Masonry columns={isDesktop ? 4 : 2} spacing={isDesktop ? 2 : 1}>
                    {state.imageList.map((gen) => (
                        <Card style={{ cursor: 'pointer' }} key={gen.id}>
                            <div style={{ position: "relative" }}>
                                {/* <CircularProgress style={{ position: 'absolute', zIndex: 1, bottom: '12px', right: '12px' }} /> */}
                                <IconButton aria-label="delete"
                                    // disabled={true}
                                    onClick={() => deleteGen(gen.id)}
                                    style={{ position: 'absolute', zIndex: 1, bottom: '12px', right: '12px' }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                <CardActionArea>
                                    {(gen.gen_image_small) ?
                                        <CardMedia
                                            component="img"
                                            height="auto"
                                            image={gen.gen_image_small}
                                            alt="green iguana"
                                            // style={{ aspectRatio: "1024/768" }}
                                            onClick={() => clickCard(gen.id)}
                                        />
                                        :
                                        <Skeleton variant="rectangular" width="100%" height="207px" />
                                    }

                                    <CardContent sx={{ mt: '-64px', backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'absolute', width: '100%', p: '10px', border: '' }} >
                                        <Typography variant="body1" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {gen.gen_data.Space}
                                        </Typography>

                                        <Typography variant="body2" color="text.secondary" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {gen.gen_data.DesignTheme}
                                        </Typography>

                                    </CardContent>
                                </CardActionArea>
                            </div>
                        </Card>
                    ))}
                </Masonry>
            </Box>

            <GenViewer modalState={modalState} setModalState={setModalState}></GenViewer>
            <Box display="flex" justifyContent="center">

                <Pagination
                    hidden={state.totalPages === null}
                    sx={{ m: 2 }}
                    count={state.totalPages}
                    page={state.currentPage}
                    onChange={changePage}

                />
            </Box>

        </React.Fragment>

    );
}
