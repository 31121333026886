import React, { useState, useEffect, useContext } from 'react';
import { StateContext, Apis } from '../App'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from "@mui/system";
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ReactCompareSlider, ReactCompareSliderImage, ReactCompareSliderHandle } from 'react-compare-slider';



// .btn-grad {
//     background-image: linear-gradient(to right, #DC2424 0%, #4A569D  51%, #DC2424  100%);
//     margin: 10px;
//     padding: 15px 45px;
//     text-align: center;
//     text-transform: uppercase;
//     transition: 0.5s;
//     background-size: 200% auto;
//     color: white;            
//     box-shadow: 0 0 20px #eee;
//     border-radius: 10px;
//     display: block;
//   }

//   .btn-grad:hover {
//     background-position: right center; /* change the direction of the change here */
//     color: #fff;
//     text-decoration: none;
//   }



const GradientButton = styled(Button)`         
background-image: linear-gradient(to right, #DC2424 0%, #4A569D  51%, #DC2424  100%);
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    border-radius: 5px;
    display: block;
    

  &:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

`;



export default function HomePage() {
    const { state, setState } = useContext(StateContext);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const [value, setValue] = React.useState(0);
    const imgUrls = {
        "0": [
            "https://storage.googleapis.com/interiorai-bucket/interior%20examples/Bedroom%20Modern%20(Orig).png"
            , "https://storage.googleapis.com/interiorai-bucket/interior%20examples/Bedroom%20Modern%20(Gen).png"
        ],
        "1": [
            "https://storage.googleapis.com/interiorai-bucket/interior%20examples/Lounge%20Classical%20(Orig).png"
            , "https://storage.googleapis.com/interiorai-bucket/interior%20examples/Lounge%20Classical%20(Gen).png"
        ],
        "2": [
            "https://storage.googleapis.com/interiorai-bucket/interior%20examples/Living%20Room%20Elegant%20Country%20(Orig).png"
            , "https://storage.googleapis.com/interiorai-bucket/interior%20examples/Living%20Room%20Elegant%20Country%20(Gen).png"
        ],
        "3": [
            "https://storage.googleapis.com/interiorai-bucket/interior%20examples/Lounge%20Hollywood%20Galm%20(Orig).png"
            , "https://storage.googleapis.com/interiorai-bucket/interior%20examples/Lounge%20Hollywood%20Galm%20(Gen).png"
        ],
        "4": [
            "https://storage.googleapis.com/interiorai-bucket/interior%20examples/Home%20Office%20Industrial%20(Orig).png"
            , "https://storage.googleapis.com/interiorai-bucket/interior%20examples/Home%20Office%20Industrial%20DS(Gen).png"
        ],
        "5": [
            "https://storage.googleapis.com/interiorai-bucket/interior%20examples/Bedroom%20Art%20Deco%20(Orig).png"
            , "https://storage.googleapis.com/interiorai-bucket/interior%20examples/Bedroom%20Art%20Deco%20(Gen).png"

        ]
    }
    const [origImage, setOrigImage] = useState(imgUrls['0'][0])
    const [genImage, setGenImage] = useState(imgUrls['0'][1])
    const handleChange = (event, newValue) => {
        setValue(newValue);
        console.log(event)
        console.log(newValue, imgUrls["Modern"])
        setOrigImage(imgUrls[newValue][0])
        setGenImage(imgUrls[newValue][1])
    };
    useEffect(() => {

    }, [])
    return (
        <Container maxWidth="lg">
            <Box sx={{ flexGrow: 1, mt: isDesktop ? 5 : 2 }} >
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h4" style={{ color: '#58c697', fontWeight: 600 }}>
                            Interior Design AI Generator
                        </Typography>

                        <Typography variant="body1" sx={{ mt: 5 }} style={{ color: '#c9c9c9' }}>
                            Create your dream home or room with our free AI-powered online design tools.
                            Just upload a photo of your space and explore stunning interior and exterior design ideas.
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 1 }} style={{ color: '#c9c9c9' }}>
                            Whether you're looking to spruce up a single room, our tools will help you visualize the possibilities and bring your vision to life.
                        </Typography>

                        <Typography variant="body1" sx={{ mt: 5 }} style={{ textDecoration: 'underline' }}>
                            Want to know how it works?
                        </Typography>

                        <GradientButton fullWidth
                            onClick={() => setState({ ...state, pageName: 'generate' })}
                            sx={{ mt: 5 }}
                        >

                            <Typography variant="body1">
                                Try Interior AI
                            </Typography>
                        </GradientButton>

                        <Typography variant="body2" sx={{ mt: 5 }} style={{ color: '#c9c9c9' }}>
                            Please be aware that any images uploaded and rendered using our free version may be used for commercial and promotional purposes by Collov, and will be made public.
                        </Typography>

                    </Grid>
                    <Grid item xs={12} md={8} sx={{ mb: 2 }} >
                        <Typography variant="h4" style={{ color: '#c9c9c9', fontWeight: 600 }}>
                            Explore Real-Time Latest Renders
                        </Typography>
                        <Box>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                sx={{
                                    [`& .${tabsClasses.scrollButtons}`]: {
                                        '&.Mui-disabled': { opacity: 0.3 },
                                    },
                                }}
                            >
                                <Tab label="Modern" />
                                <Tab label="Classical" />
                                <Tab label="Elegant Country" />
                                <Tab label="Hollywood Galm" />
                                <Tab label="Industrial" />
                                <Tab label="Art Deco" />
                            </Tabs>
                        </Box>
                        <Box sx={{ mt: 2 }} style={{ position: "relative" }}>
                            <Typography variant="body1" style={{ color: 'black', position: 'absolute', zIndex: 1000 }} sx={{ m: 1 }}>
                                Before
                            </Typography>
                            <Typography variant="body1" style={{ color: 'black', position: 'absolute', zIndex: 1000, right: 0 }} sx={{ m: 1 }}>
                                After
                            </Typography>
                            <ReactCompareSlider
                                handle={<ReactCompareSliderHandle buttonStyle={{ WebkitBackdropFilter: 'none', backdropFilter: 'none', border: 0, boxShadow: 'none' }} linesStyle={{ opacity: 0 }} />}
                                itemOne={<ReactCompareSliderImage src={origImage} srcSet={origImage} alt="Original Image" />}
                                itemTwo={<ReactCompareSliderImage src={genImage} srcSet={genImage} alt="Generated Image" />}
                                position={50}
                            />

                        </Box>

                    </Grid>
                </Grid>
            </Box>
        </Container >
    );
}