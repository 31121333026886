import React, { useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
// import CssBaseline from '@mui/material/CssBaseline';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { Button, CardActions } from '@mui/material';
import { StateContext } from '../App'




export default function ImageEditor() {
    const { state, setState } = useContext(StateContext);

    useEffect(() => {

    }, [state.image])
    // const handleUploadClick = event => {
    //     console.log("Image upload click")
    //     var file = event.target.files[0];
    //     const reader = new FileReader();
    //     var url = reader.readAsDataURL(file);
    //     reader.onload = function (e) {
    //         var img = new Image();
    //         img.src = reader.result;

    //         img.onload = function () {
    //             setState(state => ({ ...state, image: img.src, canvasImage: img.src }))
    //         }
    //     }
    // };
    const handleUploadClick = event => {
        var file = event.target.files[0];

        const reader = new FileReader();
        reader.onload = function (e) {
            var img = new Image();
            img.src = reader.result;

            img.onload = function () {
                setState(state => ({ ...state, image: img.src, canvasImage: img.src }));
            }
        };

        reader.readAsDataURL(file);
    };
    const removeImage = () => {
        setState({
            ...state
            , image: null
            , isMask: false
            , segmentMask: null
            , bwMask: null
            , maskOptions: []
            , selectedMaskOptions: []
            , canvasImage: null
        })
    }
    return (
        <Box>
            <Typography variant='h5'>Upload an Image</Typography>
            <Card hidden={state.image === null} style={{ marginBottom: '15px', marginTop: '10px' }}>
                {/* <CardMedia
                    component="img"
                    height="140"
                    src={state.image}
                    style={{ height: 'auto' }}
                /> */}
                <CardMedia
                    component="img"
                    height="140"
                    src={state.canvasImage}
                    style={{ height: 'auto' }}
                />
                {/* BW Mask
                <CardMedia
                    component="img"
                    height="140"
                    src={state.bwMask}
                    style={{ height: 'auto' }}
                />
                Seg Mask
                <CardMedia
                    component="img"
                    height="140"
                    src={state.segmentMask}
                    style={{ height: 'auto' }}
                /> */}
                <CardActions>
                    <Button size="small" color="primary" onClick={removeImage}>
                        Remove
                    </Button>
                </CardActions>
            </Card>
            <Box textAlign='center' sx={{ p: 10, border: '1px dashed grey', mt: 1 }} hidden={state.image !== null}>

                <Button component="label">
                    Upload<span style={{ color: 'red' }}>*</span>
                    <input hidden accept="image/png, image/jpeg" type="file" onChange={handleUploadClick} />
                </Button>
                <br></br>
                <Typography variant="body2" style={{color:'grey'}}>This size will have the best results (1024 x 768) </Typography>
                {/* <Button >Upload Image</Button>
                <input hidden accept="image/*" multiple type="file" /> */}
            </Box>
        </Box >

    )
}
